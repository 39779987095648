@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* @import url('http://fonts.cdnfonts.com/css/gotham');     */
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Gotham-Light.otf');
}
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 300;
  src:  url('./assets/fonts/Gotham-XLight.otf');
}
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Gotham-Bold.otf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Gotham', sans-serif;
  outline: none;
}
input{
  outline: none;
}
textarea{
  outline: none;
}
button{
  outline: none;
}
a{
  text-decoration: none;
  font-weight: 500;
}
.sticky{
  /*Update*/
  /* background: url('./assets/background.jpg'); */
  /* background: hsla(100, 50%, 100%, 0.1);
  backdrop-filter: blur(1rem); */
  /*/*/
    
  
  background: linear-gradient(to right,rgba(255,255,255,.85),rgba(0, 0, 0,.5),rgba(0, 0, 0,.8)),url('./assets/background.jpg') no-repeat center;
  background-position: center;
  background-size: cover;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  /* box-shadow: 0 2px 6px 0 rgb(0 0 0 / 12%); */

}
.sticky .blur{
    background: hsla(0, 0%, 100%, 0.1);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}
.sticky .logo-2{
  display: none;

}
.logo-1{
  display: none;
}
.sticky .logo-1{
  display: flex;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.navbaritems{
  display: none;
}
.navbarlist{
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  height: 100vh;
  width: 100%;
  background: linear-gradient(to right,rgba(0,0,0,.8),rgba(0,0,0,.6)),url('./assets/background.jpg');
  background-size:cover;
  justify-content: center;
  padding-top:100px;
  transition: 300ms ease-in-out;
  
}
.navbarlist ul{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 50px 0 250px 0;
}
.navlink{
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 17.5px;
  /*Update*/
  color: #fff;
  /**/
  /* color: #fff; */
  padding:0;
  transition: 200ms ease;
  position: relative;
  user-select: none;
  -webkit-user-select: none;       
  -moz-user-select: none; 
  -ms-user-select: none; 
}
.navlink:hover{
    color: #42A7FF;
}
.navlink::after{
  content: '';
  height: 3px;
  width: 0;
  position: absolute;
  background: #42A7FF;
  bottom: -6px;
  left: 0;
  transition: 300ms;
}
.navlink:hover::after{
  width: 100%;
}
.active-link{
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 17.5px;
  color: #42A7FF;
  padding:0;
  transition: 200ms ease;
  position: relative;
  user-select: none;
  -webkit-user-select: none;       
  -moz-user-select: none; 
  -ms-user-select: none; 
}
.active-link::after{
  content: '';
  height: 3px;
  width: 100%;
  position: absolute;
  background: #42A7FF;
  bottom: -6px;
  left: 0;
  transition: 300ms;
}

.sticky .logo{
    transition: 400ms ease-out;
    /*Update*/
  height: 60px;
  /* width: 60px; */
  margin: 0;

}

@media only screen and (max-width:1025px) and (min-width:769px){
  .home .title{
    font-size: 40px;
  }
  .home .minititle{
    font-size: 30px;
  }
  .about{
    align-items: center;
    overflow: hidden;
  }
  .about img{
    width: 350px;
  }
  .about div{
    padding: 20px;
  }
  .about .title{
    font-size: 28px;
  }
  .about .desc{
    font-size: 18px;
    width: auto;
    padding: 0;
  }
  .piliers img{
    height: 128px;
  }
  
  .piliers .grid{
    gap: 50px;
  }
  .piliers .item{
    width: 180px;
    height: 140px;
  }
  .piliers .item .title{
    font-size: 13px;
  }
  .piliers .item .desc{
    font-size: 13px;
  }
  .quality{
    margin-top: 500px;
  }
  .service .left{
    flex: 1;
  }
  .service .right{
    flex: 1;
  }
  .service .imgService{
    width: 500px;
    height: auto;
  }
  .service .containerinfos{
    padding: 10px ;
  }
  .service .titles{
    font-size: 20px;
    padding-bottom: 15px;
  }
  .service .items{
    padding:7px 0;
    font-size: 12px;
  }
  .contact .location-container{
    margin:100px 50px;
    
  }
  .lgbsi{
      width: 100px;
      height: 100px;
  }
  .navbar{
    padding: 0 10px 0 60px;
  }
}








